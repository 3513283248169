<!--
 * @Author: your name
 * @Date: 2022-04-26 15:20:48
 * @LastEditTime: 2023-10-11 15:35:00
 * @LastEditors: wangwang 2723008256@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\components\myFooter.vue
-->
<template>
  <div id="box">
    <div class="aboutUs">
      <div class="top">
        <ul>
          <li @click="gywm">
            关于我们
            <span>|</span>
          </li>
          <li @click="lxwm">
            联系我们
            <span>|</span>
          </li>
          <li>
            <router-link to="/helpCenter">帮助中心</router-link><span>|</span>
          </li>
          <li @click="ptxy">隐私政策<span>|</span></li>
          <li><router-link to="/feedBack">意见反馈</router-link></li>
        </ul>
        <div class="aboutMsg">
          <div class="yan">
            <img src="../../assets/img/oldlogo.png" alt="" />
          </div>
          <div class="hezuo">
            <!-- <h4>合作单位：</h4> -->
            <div class="danweiLists">
              <!-- <div class="danwei" v-for="item in linkList" :key="item.id" @click="advert(item.http_url)">
                {{item.title}}
              </div> -->
              <div class="danwei">
                精准教研教学平台是一款专门为全国各学校高中教师打造的教研教学工具，侧重一体化优质资源、体系化公益教研活动、实时性学校数据看板等内容定制化推送，集合下载、订阅、直播、分享、在线客服等实用功能为一体。平台操作方便，免费开放，是教师们随时随地的教研教学小助手。
              </div>
            </div>
          </div>
          <div class="erweima">
            <div class="gongzhonghao">
              <img :src="footerMsg.gzh_file_url" alt="" />
              <p>平台微信公众号</p>
            </div>
            <div class="xiaochengxu">
              <img :src="footerMsg.xcx_file_url" alt="" />
              <p>平台微信小程序</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" @click="getreload">
        <div>
          {{footerMsg.copyright}} 备案号：<a href="https://beian.miit.gov.cn/" style="color:#08a579" target="blank">京ICP备18064839号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysinfo, list_links } from "../../api/http";
export default {
  inject: ["reload"], //刷新页面
  props: ["footerMsg"],
  name: "myFooter",
  data() {
    return {
      // telphone:'',//客服电话
      gzh_file_url: "", //客服二维码
      xcx_file_url: "", //小程序二维码
      // copyright:'',//版权
      // beian_code:'',//备案号
      linkList: [], //友情链接列表
    };
  },
  created() {
    console.log(this.footerMsg)
    list_links({
      md5key: localStorage.getItem("md5key"),
    }).then((res) => {
      // console.log(res, "友情链接");
      this.linkList = res.retRes;
    });
  },
  watch: {},
  methods: {
    getreload() {
      // this.reload()
    },
    advert(href) {
      window.open(href, "_blank");
    },
    ptxy() {
      this.$router.push({
        name: "Sysinfo",
      });
    },
    gywm() {
      this.$router.push({
        name: "Gywm",
      });
    },
    lxwm() {
      this.$router.push({
        name: "Custom",
      });
    },
  },
};
</script>

<style lang="less" scoped>
#box {
  .aboutUs {
    width: 100%;
    background-color: #e9ebee;
    color: #999;
    .top {
      display: flex;
      flex-direction: column;
      width: 1200px;
      margin: 0 auto;
      ul {
        display: flex;
        margin: 30px auto;
        li {
          text-align: center;
          padding: 0 12px;
          color: #333;
          font-size: 14px;
          line-height: 30px;
          cursor: pointer;
          a {
            color: #333;
          }
          span {
            padding-left: 38px;
          }
        }
        li:hover{
          color: #08a579;
          span{
            color: #333;
          }
        }
      }
      .aboutMsg {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .yan{
          width: 130px;
          img{
            width: 130px;
          }
        }
        .hezuo {
          margin-left: 30px;
          margin-right: 70px;
          h4 {
            font-size: 16px;
            color: #333;
          }
          .danweiLists {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            width: 740px;
            .danwei {
              line-height: 24px;
              font-size: 14px;
              color: #646566;
              cursor: pointer;
            }
          }
        }
        .erweima {
          display: flex;
          img {
            width: 100px;
            height: 100px;
          }
          p {
            text-align: center;
          }
          .gongzhonghao {
            margin-right: 40px;
          }
        }
      }
    }
    .bottom {
      overflow: hidden;
      margin: 0px auto;
      padding: 20px 0;
      text-align: center;
      border-top: 1px solid #d4d4d4;
      overflow: hidden;
    }
  }
}
</style>
