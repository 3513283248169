<!--
 * @Author: your name
 * @Date: 2022-04-26 09:49:38
 * @LastEditTime: 2024-08-23 18:00:28
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\views\HomeView.vue
-->
<template>
  <div class="home">
    <header>
      <div class="header">
        <myHeader :path="path" @searchRes="searchRes" :headerMsg="headerMsg" :ziyuanPath="ziyuanPath"/>
      </div>
    </header>
    <main>
      <router-view @pathpath = "pathpath"  :xitongMsg="footerMsg" :kefuMsg="kefuMsg"/>
      <div class="floating" v-if="path != '/active/fullScreen'">
        <floating :floaterMsg="floaterMsg" />
      </div>
      <!-- 活动预约弹窗 活动学习提醒弹窗 -->
      <div class="activekctc" v-if="true">
        <el-dialog :visible.sync="actcyytxShow" width="1000px">
          <div class="yytxBox">
            <h3 v-if="bm_status == 0">活动预约课程表</h3>
            <h3 v-if="bm_status == 1">学习提醒</h3>
            <div class="tcContent">
              <div class="tcleft">
                <img :src="hb_file_url" alt="" />
              </div>
              <div class="tcright">
                <div class="danyuan" v-for="item in zhang_lists" :key="item.id">
                  <div
                    class="mltitle"
                    @click="choseDy(item.id, item.zytj_status)"
                  >
                    <h3>{{ item.title }}</h3>
                    <div class="jiantou">
                      <span
                        ><img
                          src="../assets/img/acrestop.png"
                          v-if="mulutapValue == item.id"
                      /></span>
                      <span
                        ><img
                          src="../assets/img/acresdown.png"
                          v-if="mulutapValue != item.id"
                      /></span>
                    </div>
                  </div>
                  <div
                    class="muluLists"
                    v-show="mulutapValue == item.id"
                    :class="{
                      nowmulu: list.xxjd_info.status == 1,
                      beforemulu:
                        list.start_time_true * 1000 > new Date().getTime(),
                    }"
                    v-for="(list, index) in item.jie_lists"
                    :key="list.id"
                  >
                    <div
                      class="leftTips"
                      v-show="list.xxjd_info.status == 2"
                    ></div>
                    <div
                      class="leftTips"
                      style="background-color: #08a579"
                      v-show="
                        list.xxjd_info.status != 2 &&
                        list.start_time_true * 1000 < new Date().getTime()
                      "
                    ></div>
                    <div
                      class="leftTips"
                      style="background-color: #ccc"
                      v-show="
                        list.start_time_true * 1000 > new Date().getTime()
                      "
                    ></div>
                    <div class="muluIndex">
                      <h3>0{{ index + 1 }}</h3>
                    </div>
                    <div class="mulumsg">
                      <div class="listTitle">
                        {{ list.title }}
                      </div>
                      <div class="mulubottom">
                        <div class="timelong">
                          课程时长：{{ list.shichang / 60 }}min
                        </div>
                        <div class="studyjd">
                          学习进度：{{ list.xxjd_info.jd }}%
                        </div>
                      </div>
                    </div>
                    <div class="muluright">
                      <div
                        class="yixuewan zhuangtai"
                        v-show="
                          list.xxjd_info.status == 2 &&
                          list.start_time_true * 1000 < new Date().getTime()
                        "
                      >
                        <img src="../assets/img/acresyixuewan.png" alt="" />
                        <span>已学完</span>
                      </div>
                      <div
                        class="yixuewan zhuangtai"
                        v-show="
                          list.xxjd_info.status == 1 &&
                          list.start_time_true * 1000 < new Date().getTime()
                        "
                      >
                        <img src="../assets/img/acresxuexizhong.png" alt="" />
                        <span>学习中</span>
                      </div>
                      <div
                        class="yixuewan zhuangtai"
                        v-show="
                          list.xxjd_info.status == 0 &&
                          list.start_time_true * 1000 < new Date().getTime()
                        "
                      >
                        <img src="../assets/img/acresweixuexi.png" alt="" />
                        <span>未学习</span>
                      </div>
                      <div
                        class="yixuewan zhuangtai"
                        v-show="
                          list.start_time_true * 1000 > new Date().getTime()
                        "
                      >
                        <img src="../assets/img/acresweikaiqi.png" alt="" />
                        <span>未开启</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dosome" v-if="bm_status == 0" @click="goacdetails">查看详情</div>
            <div class="dosome" v-if="bm_status == 1" @click="goacdetails">立即学习</div>
          </div>
        </el-dialog>
      </div>
    </main>
    <footer>
      <div
        class="footer"
        v-if="
          path != '/dingzhi'||
          path != '/resource/jiaoyanClass' ||
          path != '/product/fullScreenBooks'
        "
      >
        <myFooter :footerMsg="footerMsg" />
      </div>
    </footer>
  </div>
</template>

<script>
import myHeader from "./components/myHeader.vue";
import myFooter from "./components/myFooter.vue";
import floating from "../components/floating/floating.vue";
import { sysinfo, tk_activity ,info_activity,nowkemu} from "../api/http";
export default {
  inject: ["reload"], //刷新页面
  name: "HomeView",
  components: {
    myHeader,
    myFooter,
    floating,
  },
  data() {
    return {
      path: "",
      headerMsg: { title: "", logo: "" },
      footerMsg: { gzh_file_url: "", kf_file_url: "", xcx_file_url: "",copyright:'' },
      floaterMsg: { telphone: "", kf_file_url: "", xcx_file_url: "" },

      actcyytxShow: false, //活动预约课程学习提醒弹窗
      bm_status:'',//是否报名
      hb_file_url:'',//弹窗左侧海报
      zhang_lists:'',//活动章节列表
      mulutapValue:'',//章节值
      acid:'',//弹窗活动id

      ziyuanPath:'',//资源详情页传递的跳转路径

      kefuMsg:{},//客服相关信息
      kemu_id:'',
    };
  },
  created() {
    // console.log(this.$route.path,"当前的路由信息")
    this.getsysinfo();
    this.isalert();
    // setTimeout(()=>{
      this.pathpath();
    // },1000)
    
  },
  mounted() {
    // console.log(this.$route.path,"homeMonted");
    const floating = document.getElementsByClassName("floating")[0];
    const footer = document.getElementsByClassName("footer")[0];
    const header = document.getElementsByClassName("header")[0];
    if (this.$route.path == "/active/fullScreen") {
      // floating.style.display = "none";
      // footer.style.display = "none";
    } else if (this.$route.path == "/dingzhi") {
      footer.style.display = "none";
    } else if (this.$route.path == "/resource/jiaoyanClass") {
      footer.style.display = "none";
    } else if (this.$route.path == "/product/fullScreenBooks") {
      header.style.display = "none";
      floating.style.display = "none";
      footer.style.display = "none";
    } else {
      header.style.display = "block";
      floating.style.display = "block";
      footer.style.display = "block";
    }
  },
  updated() {
    // console.log(this.$route.path,"homeUpdated");
    const header = document.getElementsByClassName("header")[0];
    const floating = document.getElementsByClassName("floating")[0];
    const footer = document.getElementsByClassName("footer")[0];
    if (this.$route.path == "/active/fullScreen") {
      header.style.display = "block";
      floating.style.display = "none";
      footer.style.display = "none";
    } else if (this.$route.path == "/dingzhi") {
      header.style.display = "block";
      footer.style.display = "none";
    }else if (this.$route.path == "/resource/jiaoyanClass") {
      header.style.display = "block";
      footer.style.display = "none";
    } else if (this.$route.path == "/product/fullScreenBooks") {
      header.style.display = "none";
      floating.style.display = "none";
      footer.style.display = "none";
    } else {
      header.style.display = "block";
      floating.style.display = "block";
      footer.style.display = "block";
    }
  },
  watch: {
    // $route(to, from) {
    //   const str = to.path.split("/");
    //   this.path = "/" + str[1];
    //   // console.log(this.path, "123456678909");
    //   // localStorage.setItem("path", this.path);
    // },
  },
  methods: {
    //获取系统信息
    getsysinfo() {
      sysinfo({
        md5key: localStorage.getItem("md5key"),
      }).then((res) => {
        // console.log(res,"shuaxin")
        const data = res.retRes;
        this.headerMsg.title = data.school_title;
        this.headerMsg.logo = data.school_logo;
        this.footerMsg.gzh_file_url = data.gzh_file_url;
        this.footerMsg.xcx_file_url = data.xcx_file_url;
        this.footerMsg.kf_file_url = data.kf_file_url;
        this.footerMsg.copyright = data.copyright;
        this.floaterMsg.telphone = data.telphone;
        this.floaterMsg.kf_file_url = data.kf_file_url;
        this.floaterMsg.xcx_file_url = data.xcx_file_url;
        this.kefuMsg.zykf_file_url = data.zykf_file_url;
        this.kefuMsg.zykf1_file_url = data.zykf1_file_url;
        this.kefuMsg.zykf_account = data.zykf_account;
        this.kefuMsg.hdkf_file_url = data.hdkf_file_url;
        this.kefuMsg.hdkf_account = data.hdkf_account;
        this.kefuMsg.qwkf_file_url = data.qwkf_file_url;

        // islogin({md5key: localStorage.getItem("md5key"),}).then(res=>{
        //   if(res.retRes.login_status == 1){
        //     this.checkShow = 1
        //   }
        // })
      });
    },
    pathpath(e){
      // console.log(e,"资源详情页传递的跳转路径")
      this.ziyuanPath = e
    },
    searchRes(keyword) {
      this.$router.push({
        name: "SearchRes",
        query: {
          keyword: keyword,
          path: this.$route.path,
        },
      });
      this.reload(); //刷新页面
    },
    //判断系列活动弹窗
    isalert() {
      tk_activity({
        md5key: localStorage.getItem("md5key"),
      }).then((res) => {
        // console.log(res,"是否弹窗")
        const data = res.retRes;
        if (
          data.alert_id != 0 &&
          data.alert_id != localStorage.getItem("alert_id")
        ) {
          this.acid = data.activity_id
          localStorage.setItem("alert_id", data.alert_id);
          info_activity({
            md5key: localStorage.getItem("md5key"),
            id: this.acid,
          }).then((res) => {
            if (res.retInt == 1) {
              // console.log(res, "活动详情");
              const data = res.retRes;
              this.hb_file_url = data.hb_file_url;
              this.bm_status = data.bm_status;
              this.zhang_lists = data.zhang_lists;
              this.mulutapValue = data.zhang_lists[0].id;
              this.actcyytxShow = true
            }
          });
        } else {
          this.actcyytxShow = false
        }
      });
    },
     choseDy(id) {
      if (this.mulutapValue == id) {
        this.mulutapValue = "";
      } else {
        this.mulutapValue = id;
      }
    },
    //前往相关系列活动页
    goacdetails(){
      this.$router.push({
        name:'ActiveDetails',
        query:{
          id:3722
        }
      })
    },
  },
};
</script>
<style>
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #b3b3b3;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #b3b3b3;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b3b3b3;
}
.el-loading-spinner i{
      color:#FFF !important;
      font-size: 18px;
    }
.el-loading-spinner .el-loading-text{
      color:#FFF;
      font-size: 16px;
    }
</style>
<style lang="less" scoped>
body {
  height: 100%;
  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    header {
      position: sticky;
      top: 0;
      z-index: 1000;
      .ceshi {
        width: 1rem;
        height: 1rem;
        background-color: red;
      }
    }
    main {
      flex: 1;
      // background-color: #e4e4e4;
      background-color: #f0f2f5;
      #id {
        width: 1200px;
        margin: 0 auto;
      }
      .floating {
        display: inline-block;
        position: fixed;
        right: 20px;
        bottom: 700px;
        z-index: 100;
      }
      .activekctc {
        /deep/.el-dialog {
          margin-top: 5vh !important;
          padding: 0px 30px;
          border-radius: 14px;
          height: 730px;
        }
        /deep/.el-dialog__header {
          padding-top: 25px;
        }
        /deep/.el-dialog__body {
          padding: 0 0px;
        }
        .yytxBox {
          h3 {
            text-align: center;
            font-size: 18px;
            color: #333;
          }
          .tcContent {
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            .tcleft {
              width: 310px;
              height: 550px;
              img {
                width: 310px;
              }
            }
            .tcright {
              width: 600px;
              height: 550px;
              overflow-y: scroll;
              .danyuan {
                margin-bottom: 20px;
                .mltitle {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  // margin-top: 20px;
                  height: 54px;
                  border-radius: 10px;
                  background-color: #f5f7fa;
                  cursor: pointer;
                  h3 {
                    margin-left: 20px;
                    font-size: 16px;
                    color: #000;
                    line-height: 54px;
                  }
                  .jiantou {
                    img {
                      margin-right: 20px;
                      width: 10px;
                      height: 5px;
                    }
                  }
                }
                .mltitle:last-child {
                  margin-top: 0;
                }
                .muluLists {
                  display: flex;
                  align-items: center;

                  width: 600px;
                  height: 78px;
                  border-bottom: 1px solid #f0f3f5;
                  .leftTips {
                    width: 4px;
                    height: 72px;
                    background-color: #fc8d2f;
                  }
                  .muluIndex {
                    margin-left: 20px;
                    font-size: 20px;
                  }
                  .mulumsg {
                    display: flex;
                    flex-direction: column;
                    margin-left: 35px;
                    color: #333;
                    .listTitle {
                      margin-bottom: 10px;
                      width: 330px;
                      font-size: 14px;
                    }
                    .mulubottom {
                      display: flex;
                      font-size: 12px;
                      .studyjd {
                        margin-left: 30px;
                      }
                    }
                  }
                  .muluright {
                    margin-left: 90px;
                    .zhuangtai {
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      color: #333;
                      cursor: pointer;
                      img {
                        margin-right: 8px;
                        width: 16px;
                      }
                    }
                  }
                }
                .nowmulu {
                  background-color: #f2fefb;
                  color: #08a579;
                  .mulumsg {
                    color: #08a579;
                  }
                  .muluright {
                    span {
                      color: #08a579;
                    }
                  }
                }
                .beforemulu {
                  // background-color: #F2FEFB;
                  color: #b3b3b3;
                  .mulumsg {
                    color: #b3b3b3;
                  }
                  .muluright {
                    span {
                      color: #b3b3b3;
                    }
                  }
                }
              }
            }
            ::-webkit-scrollbar {
              width: 0px;
            }
            ::-webkit-scrollbar-track {
              background-color: #f5f5f5;
              -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
              border-radius: 5px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.2);
              border-radius: 5px;
            }
            ::-webkit-scrollbar-button {
              background-color: #eee;
              display: none;
            }
            ::-webkit-scrollbar-corner {
              background-color: black;
            }
          }
          .dosome {
            margin: 30px auto;
            text-align: center;
            line-height: 40px;
            color: #fff;
            width: 120px;
            height: 40px;
            background: #08a579;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
