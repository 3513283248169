<!--
 * @Author: your name
 * @Date: 2022-04-26 09:49:38
 * @LastEditTime: 2022-07-27 14:44:05
 * @LastEditors: wangwang 2723008256@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\App.vue
-->
<template>
  <div id="app">
      <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
export default {
 name: 'App',
 provide () {
   return{
     reload: this.reload
   }
 },
 data() {
   return {
       isRouterAlive: true
   }
 },
 methods:{
   reload(){
     this.isRouterAlive = false
     this.$nextTick(function(){
       this.isRouterAlive = true
     })
   }
 }
}
</script>

<style lang="less">
html,body,#app {
  height: 100%;
}
</style>
