<!--
 * @Author: your name
 * @Date: 2022-04-26 10:46:59
 * @LastEditTime: 2024-09-04 18:02:25
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\components\myHeader.vue
-->
<template>
  <div id="container">
    <div class="school">
      <img :src="headerMsg.logo" alt="" />
      <!-- <img src="../../assets/img/footerYan.png" alt=""> -->
      <div class="title">{{headerMsg.title}}精准教研教学平台</div>
    </div>
    <div class="nav">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#333"
        active-text-color="#08A579"
        router
      >
        <el-menu-item index="/" style="background:#FFF">首页</el-menu-item>
        <el-menu-item index="/active" v-if="kemu_id == 1 || kemu_id == 3 || !kemu_id">活动中心</el-menu-item>
        <!-- <el-menu-item index="/resource">资源中心</el-menu-item> -->
        <el-menu-item index="/resourceJx">精选资源</el-menu-item>
        <el-menu-item index="/resourceTb">同步资源</el-menu-item>
        <!-- <el-menu-item index="/product">产品中心</el-menu-item> -->
        <el-menu-item index="/personal">个人中心</el-menu-item>
      </el-menu>
    </div>
    <div class="search">
      <input type="text" placeholder="请输入关键词搜索" v-model="keyword" @keyup.enter="searchRes"/>
      <div class="anniu" @click="searchRes">
        <img src="../../assets/img/search.png" alt="" />
      </div>
    </div>
    <!-- <div class="userMsg">
      <span>早上好，听说起风了</span>
      <img src="../../assets/img/touxiang.webp" alt="用户头像" />
    </div> -->
  </div>
</template>

<script>
import {sysinfo,nowkemu,islogin} from '../../api/http'
export default {
  name: "myHeader",
  props: ["ziyuanPath",'headerMsg'],
  data() {
    return {
      keyword: "",//搜索框关键字
      activeIndex: "",//页面路径
      // logo:'',//学校logo
      // title:'',//学校名称
      kemu_id:'',
    };
  },
  created() {
    islogin({md5key: localStorage.getItem("md5key")}).then(res=>{
      if(res.retRes.login_status == 1){
        nowkemu({md5key: localStorage.getItem("md5key")}).then(res=>{
          this.kemu_id = res.retRes.kemu_id;
          sessionStorage.setItem('kemu_id',this.kemu_id)
          console.log('hahaha',this.kemu_id)
        })
      }
    })
  
    //重定向导航栏active样式
    const str = this.$route.path.split("/");
    this.activeIndex = "/" + str[1];
    // console.log('/'+str[1],"1239812748134289")
    // console.log(this.$route.path,'0987654321')
    if(this.$route.path == '/videoTeach'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/helpCenter'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/feedBack'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/customOnline'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/sysinfo'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/sysinfo/gywm'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/searchRes'){
      this.activeIndex = "/";
    }else if(this.$route.path == '/resource/gszy'){
      this.activeIndex = "/resourceJx";
    }
    else if(this.$route.path == '/resource/jiaoyanClass'){
      this.activeIndex = "/resourceTb";
    }
    else if(this.$route.path == '/resource/resAllfx'){
      this.activeIndex = "/resourceTb";
    }
  },
 
  mounted() {
    setTimeout(()=>{
      // console.log(this.$route.path,"123123")
      // console.log(this.ziyuanPath,"123123")
      // console.log(this.headerMsg,"123123")
      if(this.ziyuanPath){
        this.activeIndex = this.ziyuanPath;
      }else if(!this.ziyuanPath && this.$route.path =="/resource/resDetails"){
        this.activeIndex = "/resourceTb";
      }else if(!this.ziyuanPath && this.$route.path =="/resource/beikeziyuan"){
        this.activeIndex = "/resourceTb";
      }
      else if(!this.ziyuanPath && this.$route.path =="/resource/jiaoyanClass"){
        this.activeIndex = "/resourceTb";
      }
      else if(!this.ziyuanPath && this.$route.path =="/resource/zipResDetails"){
        this.activeIndex = "/resourceTb";
      }
      else if(!this.ziyuanPath && this.$route.path =="/resource/resDetYk"){
        this.activeIndex = "/resourceTb";
      }
      else if(!this.ziyuanPath && this.$route.path =="/resource/beikeYk"){
        this.activeIndex = "/resourceTb";
      }
    },100)
    // console.log(localStorage.getItem('path'),"顶定不定都不")zipResDetails
    // if(localStorage.getItem('path')){
    //   this.activeIndex = localStorage.getItem('path');
    // }
    
    // setTimeout(()=>{
    // this.activeIndex = localStorage.getItem('path');
    // },100)
    //  window.addEventListener('storage', function (e) {
    //     console.log(e,12312313112)
    //  })
    // window.onresize = function () {
    //   // console.log("宽度", document.documentElement.clientWidth);
    //   // console.log("高度", document.documentElement.clientHeight);
    //   // this.bannerWidth = document.documentElement.clientWidth
    // };
  },
  updated(){
    // this.activeIndex = localStorage.getItem('path');
    if(this.$route.path == '/resource/gszy'){
      this.activeIndex = "/resourceJx";
    }else if(this.$route.path == '/resource/jiaoyanClass'){
      this.activeIndex = "/resourceTb";
    }
  },
  watch: {
    //重定向导航栏active样式
    $route(to, from) {
      const str = to.path.split("/");
      this.activeIndex = "/" + str[1];
      // console.log(to.path, "123456678909");
      // localStorage.setItem('path',this.path)
      if(to.path == '/videoTeach'){
      this.activeIndex = "/personal";
    }else if(to.path == '/helpCenter'){
      this.activeIndex = "/personal";
    }else if(to.path == '/feedBack'){
      this.activeIndex = "/personal";
    }else if(to.path == '/customOnline'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/sysinfo'){
      this.activeIndex = "/personal";
    }else if(this.$route.path == '/sysinfo/gywm'){
      this.activeIndex = "/personal";
    }
    },
  },
   beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //   console.log(from,"212321112323123123")
  // })
    console.log(from,"212321112323123123")
  },
  methods: {
    searchRes() {
      this.$emit("searchRes", this.keyword);
      this.keyword = "";
    },
    handleSelect(index, indexPath) {
      // console.log(index, indexPath);
    },
  },
};
</script>
<style>
.el-menu--horizontal>.el-menu-item.is-active{
  background:#FFF !important;
}

input:-moz-placeholder,   
textarea:-moz-placeholder {   
    color: #B3B3B3;   
}   
  
input:-ms-input-placeholder,   
textarea:-ms-input-placeholder {   
    color: #B3B3B3;   
}   
  
input::-webkit-input-placeholder,   
textarea::-webkit-input-placeholder {   
    color: #B3B3B3;   
}
</style>
<style lang="less" scoped>
#container {
  display: flex;
  // justify-content: space-between;
  justify-content: space-around;
  align-items: center;
  margin: 0px auto;
  width: 100%;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px #ccc;

  .school {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .title {
      margin-left: 10px;
      font-weight: 900;
      font-size: 20px;
    }
  }
  .nav {
    margin-right: 20px;
    width: 30%;
    user-select: none;
    .el-menu {
      display: flex;
      justify-content: space-between;
      border: 0;
      .el-menu-item {
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      /deep/.el-menu-item:hover {
        background-color: #fff !important;
      }
    }
  }
  .search {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 55px;
    margin-right: 8 0px;
    user-select: none;
    width: 280px;
    input {
      padding-left: 20px;
      width: 240px;
      height: 37px;
      border: 1px solid #08a579;
      border-radius: 6px;
      outline: none;
    }
    .anniu {
      position: absolute;
      right: 2px;
      top: 0px;
      background-color: #08a579;
      border-top-right-radius: 5px;
      cursor: pointer;
      border-bottom-right-radius: 5px;
      width: 40px;
      height: 39px;
      text-align: center;
      line-height: 44px;
    }
  }
}
</style>