/*
 * @Author: wangwang 2723008256@qq.com
 * @Date: 2022-05-12 08:34:52
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @LastEditTime: 2025-01-10 11:22:09
 * @FilePath: \jzjypc4.0\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import axios from 'axios';//引入axios
import QS from 'qs';//引入qs模块
import {notify, Message} from 'element-ui';//引入提示组件
 
// axios.defaults.withCredentials = true;//携带cookie 
console.log(window.location.origin)
const url = window.location.origin + '/'
// 默认请求地址 
  // axios.defaults.baseURL = url;

  if(window.location.origin.indexOf("localhost")>-1){
    axios.defaults.baseURL = 'https://jyptwebtest.idianjiao.com/';
    // axios.defaults.baseURL = 'https://jypt.idianjiao.com/';
  }else{
    axios.defaults.baseURL = url;
  }
// if (process.env.NODE_ENV == 'development'){
//   axios.defaults.baseURL = 'https://jypt.idianjiao.com/';
// } else if (process.env.NODE_ENV == 'debug'){
//   axios.defaults.baseURL = 'https://jypt.idianjiao.com/';
// } else if (process.env.NODE_ENV == 'production'){
//   axios.defaults.baseURL = 'https://jypt.idianjiao.com/';
// }

//设置请求超时时间
axios.defaults.timeout = 10000;

// 设置请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

//拦截请求
//导入vuex
import store from '../store/index'
import router from '@/router';
//请求拦截器
axios.interceptors.request.use(
  config => {
    //每次发送请求之前判断vuex中是否存在token
    //如果存在，统一在请求头加上token
    const token = store.state.token
    token && (config.headers.Authorization = token)
    return config


    //将md5key放在请求参数中，每次携带
    // const md5key = localStorage.getItem('md5key')
    // config.data = {
    //   md5key:md5key,
    //   ...config.data
      
    // }
    // return config


  },
  error => {
    return Promise.error(error)
  }
)

 // 响应拦截器
 axios.interceptors.response.use(
  response => {   
    // if(response.data.retInt == 0 && response.data.retErr == "loginerr"){
    //   this.$notify({
    //     title:'登录错误',
    //     message:'请您先登录',
    //     type:'warning',
    //     duration:1000
    //   })
    
    //     // this.$router.push('/login')
    // }
    if( response.data.retErr == 'loginerr'){
     router.push({
      name:'Login'
     })
    }
    if(response.data.retUrl != ''){
      window.location.href = response.data.retUrl
    }
    if(response.data.retInt != 1 && response.data.retErr && response.data.retErr != 'loginerr'){
      Message.warning({
        message: response.data.retErr
      })
    }
    if (response.status === 200) {           
      return Promise.resolve(response);
    } else {            
      return Promise.reject(response);        
    }
  },
  error => {            
      if (error.response.status) {            
          switch (error.response.status) {              
              case 401:                    
                  router.push({                        
                      path: '/login',                        
                      query: { 
                          redirect: router.currentRoute.fullPath 
                      }
                  });
                  break;
              // 403 token过期
              // 登录过期对用户进行提示
              // 清除本地token和清空vuex中token对象
              // 跳转登录页面                
              case 403:
                  this.$notify.error({
                    title:'错误信息',
                        message: '登录过期，请重新登录'
                      });
                  // 清除token
                  localStorage.removeItem('token');
                  store.commit('loginSuccess', null);
                  // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                  setTimeout(() => {                        
                      vue.$router.push({                            
                          path: '/login',                            
                          query: { 
                              redirect: vue.$route.fullPath 
                          }                        
                      });                    
                  }, 1000);                    
                  break; 
              // 404请求不存在
              case 404:
                message('这是一条消息提示');
                  Notification.error({
                    title:'错误信息',
                        message: '网络请求不存在'
                      });
                  break;
              // 其他错误，直接抛出错误提示
              default:
                  // Toast({
                  //     message: error.response.data.message,
                  //     duration: 1500,
                  //     forbidClick: true
                  // });
          }
          return Promise.reject(error.response);
      }
  } 
);


//get请求
export function get(url, params){    
  return new Promise((resolve, reject) =>{        
      axios.get(url + '&ver=20231024', {            
          params: params        
      }).then(res => {
          resolve(res.data);
      }).catch(err =>{
          reject(err.data)        
  })    
});}

//post请求
export function post(url, params){    
  return new Promise((resolve, reject) =>{        
      axios.post(url + '&ver=20231024',QS.stringify(params))
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
});}
